import PropTypes from 'prop-types';
import React from "react"

import classNames from 'classnames';
import FreelanceStarterGuideImage from '../../../../content/assets/app/svg/freelance-starter-guide.svg'



export function formatMoney(amount, decimalCount = 2, decimal = ".", thousands = ",") {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
    let j = (i.length > 3) ? i.length % 3 : 0;

    return negativeSign + "$" + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
  } catch (e) {
    console.log(e)
  }
};

class Step1 extends React.Component {
  static propTypes = {
    handleGetStarted: PropTypes.func.isRequired,
    calculatorContext: PropTypes.object.isRequired
  }

  render() {
    return (
      <div className="step step1">
        <div className="row">
          <div className="body-content col-10 offset-1">
            <div className="step-description">
              <h6 className="section-title">Step {this.props.calculatorContext.step} of 5</h6>
              <h1>Hammock Freelance Starter Guide</h1>
            </div>

            <p>&#128075; Hi Friend!</p>

            <p>Are you a freelancer? Have you been running from client to client, hoping they'll pay your invoice before your bills are due? When was the last time you took a two-week vacation? How about the last time you were able to sit down and learn a new tool or improve your skills?</p>

            <p>Whether you're just getting started or you've been freelancing for years -- these are common problems that most freelancers encounter during their freelance journey.</p>

            <p>We're a small husband-and-wife team who are also freelancers. Having ridden the roller coaster that is freelancing for the last few years, we've gone through client dry spells, and months where we're so busy, we can barely breathe. </p>

            <p>The Hammock Freelance Starter Guide is designed to be helpful for freelancers of all experience levels. We wish we would have known this stuff when we started freelancing, and our entire perspective on freelancing changed when we learned it a few years into our journey.</p>

            <p>In the next few minutes, we'll walk through and learn how to:</p>
            <ul>
              <li>Determine how much money you need to make to cover your living expenses, business expenses, and taxes.</li>
              <li>Determine your Runway (i.e., How long you can live before you need to make more money)</li>
              <li>Use Runway to make better business and life decisions.</li>
            </ul>
          </div>
        </div>

        <div className="row calculator-section lifestyle-calculator-summary primary-gradient">
          <div className="col-10 offset-1 col-lg-6 offset-lg-3 text-center">
            <FreelanceStarterGuideImage className="freelance-starter-guide-image" />
            <h3>Your Guide to Freelance Sustainability In Just 5 Minutes.</h3>
            <a className="btn btn-white" href="#" onClick={this.props.handleGetStarted}>Let's Get Started!</a>
          </div>
        </div>

      </div>
    )
  }
}

export default Step1
