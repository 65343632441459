import "./SteppedProgressBar.scss"

import PropTypes from 'prop-types';
import React from "react"
import classNames from 'classnames'

class SteppedProgressBar extends React.Component {
  static propTypes = {
    stepCount: PropTypes.number.isRequired,
    currentStep: PropTypes.number.isRequired,
    handleProgressBarClick: PropTypes.func.isRequired
  }

  componentDidMount() {
  }

  renderBars = () => {
    let bars = []

    for (let step = 0; step < this.props.stepCount; step++) {
      
      let barClassNames = classNames({
        'bar': true,
        'active': this.props.currentStep > step,
      })

      bars.push(<div className={barClassNames} key={step} data-step={step+1} onClick={(step+1) < this.props.currentStep ? this.props.handleProgressBarClick : (e)=>{} }>&nbsp;</div>)
    }
    return bars
  }

  render() {
    return (
      <div className="stepped-progress-bar">
        {this.renderBars()}
      </div>
    )
  }
}

export default SteppedProgressBar


