import PropTypes from 'prop-types';
import React from "react"


class Step4 extends React.Component {
  static propTypes = {
    handleBankAccountBalance: PropTypes.func.isRequired,
    calculatorContext: PropTypes.object.isRequired
  }
  constructor(props) {
    super(props)
    this.bankAccountBalance = React.createRef()
  }

  handleContinueBtn = (e) => {
    e.preventDefault()
    this.props.handleBankAccountBalance(this.bankAccountBalance.current.value)
  }

  render() {
    return (
      <div className="step step1">
        <div className="row">
          <div className="col-10 offset-1">
            <div className="step-description">
              <h6 className="section-title">Step {this.props.calculatorContext.step} of 5</h6>
              <h2>Let’s Figure Out Your Runway!</h2>

              <p>To us, Runway is the single most important metric you can track as a freelancer.  Knowing your Runway simply means knowing how long you can pay yourself before you run out of money.</p>
              <p>Once you know that you can pay yourself for three months, you might decide to take a guilt-free two week vacation. You could take a step back and invest some time into learning new skills that will allow you to raise your rates. You can even use it to determine whether to turn down a project -- if you have three months in the bank, you won't feel forced to work with clients who aren't a good fit.</p>
              <p>The last piece of information we need to figure things out is the current balance in your business bank account. If you don't have a business bank account -- your personal bank account balance should work.</p>
            </div>
          </div>
        </div>

        <div className="row calculator-section grey">
          <div className="col-12 text-center">
            <label>What is your current business bank account balance?</label>
          </div>
          <div className="col-10 offset-1 col-sm-6 offset-sm-3 col-lg-4 offset-lg-4">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">$</div>
              </div>
              <input defaultValue={this.props.calculatorContext.bankAccountBalance === 0 ? "" : this.props.calculatorContext.bankAccountBalance} type="number" step="0.01" className="form-control" name="salaryGoal" id="salary-goal" placeholder="0.00" ref={this.bankAccountBalance} />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12 text-center">
            <a className="btn btn-primary continue-btn" href="#" onClick={this.handleContinueBtn}>Last Step: Show Me My Runway! &rarr;</a>
          </div>
        </div>
      </div>
    )
  }
}

export default Step4


