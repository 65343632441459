import PropTypes from 'prop-types';
import React from "react"

import { formatMoney } from './Step1';


class Step3 extends React.Component {
  static propTypes = {
    handleBusinessExpenses: PropTypes.func.isRequired,
    calculatorContext: PropTypes.object.isRequired
  }
  constructor(props) {
    super(props)
    this.businessExpenses = React.createRef()
    this.state = {
      categories: {
        "idx1": {
          label: "Software Subscriptions",
          value: 0,
        },
        "idx2": {
          label: "Co-Working Space Membership",
          value: 0,
        },
        "idx3": {
          label: "Office Supplies",
          value: 0,
        },
        "idx4": {
          label: "Business Insurance",
          value: 0,
        },
        "idx5": {
          label: "Other",
          value: 0,
        }
      }
    }
  }

  handleExpenseUpdate = (e) => {
    e.preventDefault()
    let idx = e.target.dataset.idx

    this.setState({
      categories: {
        ...this.state.categories,
        [idx]: {
          label: this.state['categories'][idx].label,
          value: Number(e.target.value),
        }
      }
    })
  }

  handleAddCategory = (e) => {
    e.preventDefault()
    let timestamp = Math.round((new Date()).getTime() / 1000)

    this.setState({
      categories: {
        ...this.state.categories,
        [`idx${timestamp}`]: {
          label: '',
          value: 0,
        }
      }
    })
  }

  handleContinueBtn = (e) => {
    e.preventDefault()
    this.props.handleBusinessExpenses(this.sumExpenses())
  }

  sumExpenses = () => {
    // sum code from https://codeburst.io/javascript-arrays-finding-the-minimum-maximum-sum-average-values-f02f1b0ce332
    return Object.keys(this.state.categories).map(key => {
      return this.state.categories[key].value
    }).reduce((a,b) => a + b, 0)
  }




  render() {
    return (
      <div className="step step2">
        <div className="row">
          <div className="col-10 offset-1">
            <div className="step-description">
              <h6 className="section-title">Step {this.props.calculatorContext.step} of 5</h6>
              <h2>What Are Your Monthly Business Expenses?</h2>
              <p>As a freelancer, you probably have some monthly recurring business expenses.</p>
              <p>Take a few minutes to check your bank account and tally up all of your business expenses. Below you'll want to fill in your average monthly business expense spending. Make sure you don't forget about year-end accountant fees or yearly subscriptions that might be hiding from your normal monthly transactions.</p>
              <p>Again, the closer these numbers are to reality -- the better!</p>
            </div>
          </div>  

          <div className="col-12 col-md-10 offset-md-1">
            <div className="row calculator-section grey">
              <div className="col-10 offset-1">
                <form className="form monthly-expenses-form" ref={this.personalExpensesForm}>
                  <div className="form-row">
                    <div className="col-6 col-md-8">
                      <strong>Category</strong>
                    </div>
                    <div className="col-6 col-md-4 ml-auto">
                      <strong>Monthly Budget</strong>
                    </div>
                  </div>

                  {Object.keys(this.state.categories).map((key) => {
                    let category = this.state.categories[key]
                    if (category === undefined) {
                      return ""
                    }
                    return (
                      <div className="form-row" key={key}>
                        <div className="col-6 col-md-8">
                          <input className="form-control category-name" data-idx={key} type="text" defaultValue={category.label} placeholder="New Category"></input>
                        </div>

                        <div className="input-group col-6 col-md-4 ml-auto">
                          <div className="input-group-prepend">
                            <div className="input-group-text">$</div>
                          </div>
                          <input type="number" data-idx={key} className="form-control" placeholder="0" onChange={this.handleExpenseUpdate} />
                        </div>
                      </div>
                    );
                  })}


                  <div className="form-row">
                    <div className="col-2">
                      <a href="#" onClick={this.handleAddCategory}>Add Category</a>
                    </div>
                    <div className="col-4 col-md-6 text-right">
                      <strong>Total:</strong>
                    </div>

                    <div className="col-6 col-md-4">
                      <span className="total">
                        {formatMoney(this.sumExpenses())}
                      </span>
                    </div>
                  </div>

                </form>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12 text-center">
            <a className="btn btn-primary continue-btn" href="#" onClick={this.handleContinueBtn}>Next Step: Bank Account Balance &rarr;</a>
          </div>
        </div>
      </div>
    )
  }
}

export default Step3


