import "../scss/pages/LifestyleCalculator.scss"
import { Link } from "gatsby"
import Img from 'gatsby-image'

import React from "react"
import SEO from "../components/marketing/seo"
import Header from "../components/marketing/Header/Header.jsx"
import Footer from "../components/marketing/Footer/Footer.jsx"

import FreelanceStarterGuide from '../components/marketing/FreelanceStarterGuide/FreelanceStarterGuide.jsx'

export const pageQuery = graphql`
query {
  runwayCalculatorChart: file(relativePath: { eq: "runway-calculator-chart.png" }) {
    childImageSharp{
      fluid(quality:60) {
        ...GatsbyImageSharpFluid
      }
    }
  }
}
`

class FreelanceStarterGuidePage extends React.Component {

  render() {
    return (
      <React.Fragment>
        <SEO title={`Hammock - Freelance Starter Guide`}
             description={`Learn how much you should be making every month to cover your paycheck, business expenses, and taxes.`}
             keywords={['freelance starter guide', 'freelancer finances', 'freelancer taxes', 'freelancer paycheck', 'freelance paycheck']}
        />

        <Header />

        <div className="freelance-lifestyle-calculator-page container">
          <FreelanceStarterGuide />
        </div>

        <Footer />
      </React.Fragment>
    )
  }
}

export default FreelanceStarterGuidePage
